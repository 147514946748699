.app {
  background: white;
  max-width: 500px;
  margin: 100px auto 16px;
  padding: 32px 24px;
  border-radius: 3px;

  h1 {
    font-size: 22px;
    font-weight: 300;
    color: rgba(0, 0, 0, 0.6);
    margin: 0 0 16px;
  }

  h2 {
    color: #ffa100;
    font-weight: bold;
    font-size: 16px;
    margin: 0 0 8px;
  }

  p {
    line-height: 140%;
    margin: 16px 0 24px;
    font-size: 14px;
  }
}

@media (max-width: 600px) {
  .app {
    margin-top: 0;
    background: white;
    box-shadow: none;
  }
}