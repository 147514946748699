.cmdItem {
  code {
    display: block;
    overflow-x: scroll;

    pre {
      margin-top: 0;
    }
  }
}
