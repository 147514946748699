.session {
  summary {

    font-size: 1.17em;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
  }

  .channelsList {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-gap: 6px;
    list-style: none;
    text-align: center;
    padding-left: 0;
    li {
      input {
        display: none;
      }
      input[type="checkbox"]:checked + label {
        background-color: #006da3;
        color: white;
      }
      label {
        background-color: lightgray;
        border-radius: 3px;
        display: block;
        width: 100%;
        padding: 5px 0;
        cursor: pointer;
      }

    }
  }
}