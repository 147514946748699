.input {
  position: relative;
  margin-top: 16px;

  &__label {
    display: block;
    position: absolute;
    top: -8px;
    left: 16px;
    background-color: white;
    padding: 0 8px;
  }

  &__field:focus {
    border: none;
    outline: none;
  }

  &__field[type=text],
  &__filediv {
    display: block;
    padding: 12px;
    border: 2px solid black;
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box;
  }

  &__field[type=file] {
    display: block;
    width: 100%;
  }

}