body {
  background: #ECEFF1;
  color: rgba(0, 0, 0, 0.87);
  font-family: Roboto, Helvetica, Arial, sans-serif;
  margin: 0;
  padding: 0;
}

@media (max-width: 600px) {
  body {
    margin-top: 0;
    background: white;
    box-shadow: none;
    border-top: 16px solid #ffa100;
  }
}